export default {
  data: () => ({
    dialogPacket: false,
    dialogPacketType: '',
  }),

  methods: {
    openDialogPacket(data, type) {
      type === 'info' ? (this.selectedPacket = JSON.parse(data)) : (this.selectedPacket = data)
      this.dialogPacketType = type
      this.dialogPacket = true
    },
  },
}
